import React, { Component } from 'react'
import Slider from 'react-slick'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { redirectFilter } from '../../actions/home'
import BestFlour from '../../assets/images/Brands/Best Flour.png'
import Blend100 from '../../assets/images/Brands/Blend 100.png'
import Blossom from '../../assets/images/Brands/Blossom.png'
import Cattleya from '../../assets/images/Brands/Cattleya.png'
import Continental from '../../assets/images/Brands/Continental.png'
import CustomizedWheatFlourRed from '../../assets/images/Brands/Customized Wheat Flour (RED).png'
import Daisy from '../../assets/images/Brands/Daisy.png'
import FibraCrackedWheat from '../../assets/images/Brands/Fibra Cracked Wheat.png'
import FibraWholeWheat from '../../assets/images/Brands/Fibra Whole Wheat.png'
import Globe from '../../assets/images/Brands/Globe.png'
import Lilac from '../../assets/images/Brands/Lilac.png'
import MyRose from '../../assets/images/Brands/My Rose.png'
import Noodelicious from '../../assets/images/Brands/Noodelicious.png'
import Oriental from '../../assets/images/Brands/Oriental.png'
import Sampaguita from '../../assets/images/Brands/Sampaguita.png'
import Titan from '../../assets/images/Brands/Titan.png'
import Universal from '../../assets/images/Brands/Universal.png'

class ourBrands extends Component {
  render() {
    let dragging = false;
    const settings = {
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      centerMode: true,
      focusOnSelect: true,
      autoplay: true,
      autoplaySpeed: 1000,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    const brandsImages = [
      {
        id: 1,
        imageLink: BestFlour,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=piattos'
      },
      {
        id: 2,
        imageLink: Blend100,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=v-cut'
      },
      {
        id: 3,
        imageLink: Blossom,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=chippy'
      },
      {
        id: 4,
        imageLink: Cattleya,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=nova'
      },
      {
        id: 5,
        imageLink: Continental,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=mr. chips'
      },
      {
        id: 6,
        imageLink: CustomizedWheatFlourRed,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=roller coaster'
      },
      {
        id: 7,
        imageLink: Daisy,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=mang juan'
      },
      {
        id: 8,
        imageLink: FibraCrackedWheat,
        linkFilter: '?brand=C2&category=all&country=all&activeMenu=brand&product=c2'
      },
      {
        id: 9,
        imageLink: FibraWholeWheat,
        linkFilter: '?brand=GREAT%20TASTE&category=all&country=all&activeMenu=brand&product=great taste',

      },
      {
        id: 10,
        imageLink: Globe,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=magic flakes'
      },
      {
        id: 11,
        imageLink: Lilac,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=presto'

      },
      {
        id: 12,
        imageLink: MyRose,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=cream-o'
      },
      {
        id: 13,
        imageLink: Noodelicious,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=x.o.'
      },
      {
        id: 14,
        imageLink: Oriental,
        linkFilter: '?brand=PAYLESS&category=Noodles&country=all&activeMenu=brand&product=oriental'
      },
      {
        id: 15,
        imageLink: Sampaguita,
        linkFilter: '?brand=PAYLESS&category=Noodles&country=all&activeMenu=brand&product=sampaguita'
      },
      {
        id: 16,
        imageLink: Titan,
        linkFilter: '?brand=PAYLESS&category=Noodles&country=all&activeMenu=brand&product=titan'
      },
      {
        id: 17,
        imageLink: Universal,
        linkFilter: '?brand=PAYLESS&category=Noodles&country=all&activeMenu=brand&product=universal'
      },
    ]
    return (
      <div className='our-brands-container'>
        <Row>
          <Col lg={6}>
            <h1>Our Brands</h1>
            <p>Our vast portfolio of brands of exceptional taste, quality, & value.</p>
          </Col>
          <Col lg={18}>
            <div className='product-slider'>
              <div className='product-display-overlay' />
              <Slider {...settings} arrows={false}>
                {brandsImages.map((data) => {
                  return (
                    <center>
                      <img src={data.imageLink} alt='Brand Item' />
                    </center>
                  )
                })}
              </Slider>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps({ home }) {
  return home
}

export default connect(mapStateToProps, { redirectFilter })(ourBrands)
