import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import products from './products'
import productCategories from './productCategories';
import productPriceList from './productPriceList';
import filterOptions from './filterOptions';
import login from './login'
import contact from './contact'
import recentOrders from './recentOrders'
import distributor from './distributor'
import drafts from './drafts'
import home from './home'
import about from './about'
import orderStatus from './orderStatus'
import permissions from './permisisons'
import poStatus from './poStatus'
import productOrder from './productOrder'
import sapData from './sapData'
import orderSummary from './orderSummary'
import faq from './faq'
import notifications from './notifications'
import containerSummary from './containerSummary'
import isLoggedIn from './isLoggedIn'
import loggedInProducts from './loggedInProducts'
import distributorOrderSummary from "./distributorOrderSummary";
import soldTo from "./soldTo";
import shipTo from "./shipTo";
import order from "./order";
import orderDetails from "./orderDetails";
import storageLocation from "./storageLocation";
import customerPricelist from "./customerPricelist";
import orderAttachment from "./orderAttachment";

const rootReducer = combineReducers({
  route: (state = { current: null }, action) => {
    if (action.type === "ROUTE_CHANGE") {
      return { ...state, current: action.payload };
    }
    return state;
  },
  form,
  about,
  login,
  contact,
  products,
  productCategories,
  productPriceList,
  filterOptions,
  recentOrders,
  distributor,
  drafts,
  home,
  orderStatus,
  permissions,
  poStatus,
  productOrder,
  sapData,
  orderSummary,
  faq,
  notifications,
  containerSummary,
  isLoggedIn,
  loggedInProducts,
  distributorOrderSummary,
  soldTo,
  shipTo,
  order,
  orderDetails,
  storageLocation,
  customerPricelist,
  orderAttachment
})

export default rootReducer;
